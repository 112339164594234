/* You can add global styles to this file, and also import other style files */
@import "./node_modules/@thg-procure-team/procure-common-ui/resources/scss/icons";

body {
  display: none;
}

.modal-small {
  width: 400px !important;
}

.modal-xl {
  width: 700px;
  min-width: 700px;
}

.p-radiobutton:not(.p-radiobutton-checked) .p-radiobutton-box {
  border: 1px solid #7b828b !important;
}
